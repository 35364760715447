import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
const HamburgerMenu = ({ activeSection, expertiseRef, servicesRef, testimonialsRef, contactRef, heroRef ,style}) => {
    const [isOpen, setIsOpen] = useState(false);

    const menuRef = useRef(null); // New ref for the entire menu

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
            <div className="fixed mb-5 top-0 right-0 mt-5 py-4 rounded-full">
                <div 
                    className="flex flex-col gap-1 z-50 mr-5 ml-5 -mt-5 px-3 py-2 rounded-full bg-black cursor-pointer hover:opacity-80 active:opacity-60 transition-opacity transition-transform transform hover:scale-105 z-100"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className='bg-white h-[0.2rem] sm:h-[0.35rem] lg:h-[0.35rem] md:h-[0.35rem] w-8 sm:w-16 lg:w-16 md:w-16 rounded-full shadow-md'></div>
                    <div className='bg-white h-[0.2rem] sm:h-[0.35rem] lg:h-[0.35rem] md:h-[0.35rem] w-8 sm:w-16 lg:w-16 md:w-16 rounded-full shadow-md'></div>
                    <div className='bg-white h-[0.2rem] sm:h-[0.35rem] lg:h-[0.35rem] md:h-[0.35rem] w-8 sm:w-16 lg:w-16 md:w-16 rounded-full shadow-md'></div>
                    {/* <div className='bg-white h-[0.35rem] w-16 rounded-full shadow-md'></div> */}
                    {/* <div className='bg-white h-[0.35rem] w-16 rounded-full shadow-md'></div> */}
                </div>

                {isOpen && (
                    <div className="absolute top-0 -mt-5 right-0 flex flex-col w-[100vw] sm:w-[100vw] md:w-[100vw] lg:w-[100vw] bg-black rounded shadow-lg z-20">
                        {[
                            { section: 'expertise', ref: expertiseRef, text: 'About' },
                            { section: 'services', ref: servicesRef, text: 'Services' },
                            { section: 'testimonials', ref: testimonialsRef, text: 'Testimonials' },
                            { section: 'contact', ref: contactRef, text: 'Contact' },
                        ].map(({ section, ref, text }) => (
                            <button onClick={() =>{ ref.current.scrollIntoView({ behavior: 'smooth' }); setIsOpen(false)}} key={section} className="hover:bg-gray-700 p-2 rounded transition-colors">
                                <div className={`block mb-2 text-xs mt-2 ml-5 sm:text-sm md:text-base lg:text-lg text-white`}>
                                    <p>{text}</p>
                                </div>
                            </button>
                        ))}
                    </div>
                )}
            </div>
    );
}

export default HamburgerMenu;
