import React, { forwardRef } from 'react';
import Tittle from './Tittle';
import Form from './Form';
import FooterImg from '../assets/images/footerImg.jpg';
import SectionDivider from './SectionDivisor';

const Contact = forwardRef(({id}, ref) => {

  function isMobileDevice() {
      return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };
  const instagramLink = isMobileDevice() 
  ? 'instagram://user?username=trainwithstelios' 
  : 'https://www.instagram.com/trainwithstelios/';

  return (
    <div id={id} ref={ref}>
    <div 
    className="w-screen flex flex-col justify-center items-center bg-[#ffffff] sm:px-0 overflow-clip">

      <SectionDivider text={"CONTACT"}/>

        <p className="text-black font-century-gothic text-[18px] sm:text-[22px] md:text-[22px] lg:text-[22px] mx-6 mt-10">Ready to start your fitness journey with me?</p>
        <p className="text-black font-century-gothic text-[18px] sm:text-[22px] md:text-[22px] lg:text-[22px] mx-6">Fill out the booking form below to schedule your first session. </p>
        <p className="text-black font-century-gothic text-[18px] sm:text-[22px] md:text-[22px] lg:text-[22px] mx-6 mb-10">If you have any questions or concerns, feel free to <a href={instagramLink} target="_blank" rel="noopener noreferrer" className="font-semibold">contact me</a>.</p>

        <Form/>

        {/* <img src={FooterImg} alt='footerImg'></img> */}

    </div>
    </div>
  );
});

export default Contact;