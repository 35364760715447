import React from 'react';
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import ThunderImage from '../assets/images/Thunder.png';  // Replace with your image path
import ThunderLine from '../assets/images/ThunderLine.png';  // Replace with your image path
import Logo1 from '../assets/images/TWS2.png';  // Replace with your image path
import HamburgerMenu from './HamburgerMenu';

export default function HeaderCustom({ activeSection, expertiseRef, servicesRef, testimonialsRef, contactRef, heroRef }) {
    return (
        <div className="relative z-50 bg-black h-16 md:h-20 lg:h-20 w-full">
            <a href='#'>

            <img src={Logo1} alt="Logo 1" className="absolute top-1/2 transform -translate-y-1/2 left-4 md:left-8 lg:left-16 h-8 md:h-10 lg:h-12" />
            </a>
            <HamburgerMenu
                expertiseRef={expertiseRef}
                servicesRef={servicesRef}
                testimonialsRef={testimonialsRef}
                contactRef={contactRef}
                activeSection={activeSection}
                ref={heroRef}
                // style="absolute top-4 right-4 md:top-6 md:right-6 lg:top-8 lg:right-8"
            />
        </div>
    );
}
