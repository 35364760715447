import React, { forwardRef } from 'react';
import HoldingBall from '../assets/images/holdingBall.jpg';
import CustomButton from './CustomButton';
import Tittle from './Tittle';
import SectionDivider from './SectionDivisor';




const Services = forwardRef(({id,contactRef}, ref) => {
    const textArr = [
        {
            text: "Personalised Workout Plans",
            sessionTime: ""
        },
        {
            text: "Personal Training",
            sessionTime: "1h session"
        },
        {
            text: "Couples Personal Training",
            sessionTime: "1h session"
        },
        {
            text: "Semi Personal Training",
            sessionTime: "1h session"
        },
        {
            text: "Postpartum Personal Training",
            sessionTime: "1h session"
        },
    ]
    return (
        <div id={id} ref={ref}>
            <SectionDivider text={"SERVICES"}/>
            <div className="min-h-[80vh] flex flex-col">
            <div className=' h-[30rem] overflow-hidden'>

                <img src={HoldingBall} alt="Holding Ball" className="w-full h-[50rem] object-cover"/>
            </div>

            <div className="flex flex-col lg:flex-row items-center justify-center my-36">
                <div className="flex flex-col w-full h-auto  items-center align-middle justify-center">
                    <h1 className="text-black text-4xl sm:text-5xl md:text-6xl lg:text-6xl font-bold font-century-gothic-bold mt-10 mb-2">GET READY TO...</h1>
                    <p className="text-black font-century-gothic ">Take the first step today and embark on a life-changing adventure with me!</p>
                    <div id='bt' onClick={()=> {}} className='flex m-5 border cursor-pointer hover:shadow-md border-black h-16 min-w-[15rem] justify-center align-middle items-center rounded-full py-6 w-[15vw]' >
                        
                        <h1 className="text-black text-4xl font-bold" onClick={()=>{

                            contactRef.current.scrollIntoView({behavior: 'smooth'});
                        }}>FREE TRIAL</h1>
                    </div>

                </div>
                <div className="flex flex-col h-auto  w-full items-center justify-center">
                    <h2 className="font-bold text-black text-[14px] md:text-[20px] lg:text-[20px] mb-3 font-century-gothic-bold text-justify  ml-[-2rem] sm:ml-[-4rem] md:ml-[-4rem] lg:ml-[-4rem]"> Different ways to work with me? </h2>
                    <div className=''>
                    <ul className="list-disc list-inside pl-5 text-justify space-y-3">
                        {textArr.map((item) => {
                            return (
                                <li className="text-black font-century-gothic text-[12px] md:text-[18px] lg:text-[18px] ">{item.text+" "}<span className="font-bold font-century-gothic-bold">{item.sessionTime}</span> </li>
                            )
                        })}
                    </ul>
                    </div>
                </div>
            </div>
        </div>

        </div>
    );
    
});

export default Services;