import React, { forwardRef, useState } from 'react';
import Tittle from './Tittle';
import { Transition } from 'react-transition-group';
import { ArrowLeftIcon, ArrowRightIcon  } from '@heroicons/react/solid';
import '../App.css'
import SectionDivider from './SectionDivisor';
import StarRating from './StarRating';

const Testimonials= forwardRef(({id},ref)=>{
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const testimonials = [
    {
        text: "Stelios is one of the best trainers I have worked with. He helps his client achieve their goal taking in consideration each ones abilities and encourage them to go forward in the way it suits each one. He also helps the client to understand what exercises he is doing and gives solutions on how to make it correctly. Highly recommended if you are looking for a professional trainer that will help you achieve your goals.",
        author: "Michalis"
      },
      {
        text: "I love the personal training so approach with Stelios. He has a very nice energy and can realy motivate you during the personal training. Thank you so much Stelios!",
        author: "Ioanna"
      },
      {
        text: "I've always struggled with getting motivated to go to the gym but ever since I joined TWS I'am actually excited to train. Best of all, I see results. I definitely recommend!",
        author: "Thomas"
      },
      {
        text:"Stelios was not only a friend but a mentor in my fitness journey. He didn't just show me the ropes, but ensured I understood the basics. His expertise always exceeded my expectations. Training with him felt like working out with a knowledgeable close friend. For those serious about fitness, Stelios is the ideal blend of friend and trainer.",
        // text:"When I embarked on my fitness journey, Stelios was there for me, eager to share both his knowledge and personal experience. As a friend, he wasn't just about showing me the ropes; he took the time to ensure I truly grasped the fundamentals. His dedication went beyond the basics as I frequently turned to him for advice, and he always surpassed my expectations with his expertise. Working out with Stelios is like training with a close friend who's also an exceptional personal trainer. I can't emphasize enough how much his guidance has shaped my fitness journey. For anyone serious about fitness, Stelios is the perfect blend of a friend and a trainer to guide you.",
        author: "Charalambos"
      },
      {
        text: "Stelio's holistic approach to wellness has not only improved my physical strength but also my mental well-being. I've gained tools and techniques that will benefit me for a lifetime.",
        author: "Carlos"
      }      
    // Add more testimonials as needed...
  ];

  const handlePrevSlide = () => {
    const prevSlide = currentSlide - 1;
    setCurrentSlide(prevSlide < 0 ? testimonials.length - 1 : prevSlide);
  }

  const handleNextSlide = () => {
    const nextSlide = currentSlide + 1;
    setCurrentSlide(nextSlide === testimonials.length ? 0 : nextSlide);
  }
return (
    <div id={id} ref={ref}>
    <div className="w-screen flex flex-col justify-center items-center bg-[#010101] sm:px-0">

        <SectionDivider text={"TESTIMONIALS"}/>
        <div className="w-screen min-h-[80vh] flex flex-col justify-center items-center bg-white p-4 sm:px-0 ">

            <div className="relative w-full max-w-screen-xl p-8 md:p-16"> {/* Adjusted this line */}
                           
                <button 
                    onClick={handlePrevSlide} 
                    className="mx-[1.5rem] sm:mx-[2.3rem] md:mx-[2.3rem] lg:mx-[2.3rem] absolute text-3xl -left-1 top-1/2 transform -translate-y-1/2 z-10 p-2 rounded-full text-black bg-transparent border border-black opacity-100 hover:opacity-50 flex items-center justify-center sm:text-4xl sm:-left-2 sm:p-3">
                    <ArrowLeftIcon className=" h-5 w-5 sm:h-6 sm:w-6" />
                </button>
                <Transition in={true} timeout={300} key={currentSlide}>
                    {(state) => (
                    <div className={`slide-${state} mt-0 sm:mt-0 md:mt-0 lg:mt-0 mx-16 sm:mx-6`}>
                        <StarRating rating={5} />
                        <p className="mt-5 text-black sm:mx-6 lg:mx-12">
                        {testimonials[currentSlide].text}
                        </p>
                        <p className="text-xl text-black relative font-bold mt-4 text-left left-[0vh] sm:left-[5vh] md:left-[5vh] lg:left-[5vh] ">
                        - {testimonials[currentSlide].author}
                        </p>
                    </div>
                    )}
                </Transition>

                <button 
                    onClick={handleNextSlide} 
                    className="mx-[1.5rem] sm:mx-[2.3rem] md:mx-[2.3rem] lg:mx-[2.3rem] absolute text-3xl -right-1 top-1/2 transform -translate-y-1/2 p-2 rounded-full text-black bg-transparent border border-black opacity-100 hover:opacity-50 flex items-center justify-center sm:text-4xl sm:-right-2 sm:p-3">
                    <ArrowRightIcon className="h-5 w-5 sm:h-6 sm:w-6" />
                </button>

            </div>
        </div>
    </div>
    </div>
);

});

export default Testimonials;