// import logo from './logo.svg';
import './App.css';
import HeroSection from './components/HeroSection';
import About from './components/About';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import HeaderCustom from './components/Header';
import { useRef } from 'react';
import { ArrowUpIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import SectionDivider from './components/SectionDivisor';
function App() {
  const heroRef = useRef(null);
  const expertiseRef = useRef(null);
  const servicesRef = useRef(null);
  const testimonialsRef = useRef(null);
  const contactRef = useRef(null);
  const [activeSection, setActiveSection] = useState("");
  useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setActiveSection(entry.target.id);
                // console.log(entry.target.id);
              }
            });
          },
          { threshold: 0.5 }
        );
        const { current: heroElem } = heroRef;
        const { current: expertiseElem } = expertiseRef;
        const { current: servicesElem } = servicesRef;
        const { current: testimonialsElem } = testimonialsRef;
        const { current: contactElem } = contactRef;

        if (heroElem) observer.observe(heroElem);
        if (expertiseElem) observer.observe(expertiseElem);
        if (servicesElem) observer.observe(servicesElem);
        if (testimonialsElem) observer.observe(testimonialsElem);
        if (contactElem) observer.observe(contactElem);

        return () => {
          if (heroElem) observer.unobserve(heroElem);
          if (expertiseElem) observer.unobserve(expertiseElem);
          if (servicesElem) observer.unobserve(servicesElem);
          if (testimonialsElem) observer.unobserve(testimonialsElem);
          if (contactElem) observer.unobserve(contactElem);
        };
    }, []);


  return (
    <div className='App '>
      <header >

       <HeaderCustom expertiseRef={expertiseRef}
                servicesRef={servicesRef}
                testimonialsRef={testimonialsRef}
                contactRef={contactRef}
                activeSection={activeSection}
                ref={heroRef}/>
        </header>

        <body className='App-header'>


              <HeroSection id="hero"
              contactRef={contactRef}
              />
              {/* Old divisor */}
              {/* <div className="h-24 w-screen bg-[#282C34] transform skew-y-2 -mt-6 -mb-6"></div> */}

              <About id="expertise" ref={expertiseRef} />

              <Services id="services" contactRef={contactRef} ref={servicesRef} />

              <Testimonials id="testimonials" ref={testimonialsRef} />

              <Contact id="contact" ref={contactRef} />

              

              {/* <div className='flex fixed w-10 h-10 top-[95%] left-[49%] items-centerjustify-center rounded-full bg-gray-700 z-60 shadow-2xl'>
              <ArrowUpIcon className="relative top-2 left-2 h-5 w-5 sm:h-6 sm:w-6" />
            </div> */}
              {/* <Footer/> */}
              
              


        </body>



    </div>
  );
}

export default App;
