import React, { forwardRef }  from 'react';
import HeroBG from '../assets/images/HeroBG_2.jpg';
import logo2 from '../assets/images/TWS.png';
import SidebarButtons from './SidebarButtons';
import CustomButton from './CustomButton';
import Logo1 from '../assets/images/Logo1.png';

const HeroSection = forwardRef(({activeSection, expertiseRef, servicesRef, testimonialsRef, contactRef,id}, ref ) =>{
    return (

      <div id={id} ref={ref} className='z-40'>

      <div
        style={{ backgroundImage: `url(${HeroBG})` }}
        className="relative w-scree h-[87vh] flex flex-col justify-center items-center bg-cover bg-center"
      >

        <div className="flex flex-col justify-center items-center mt-10 sm:mt-5">
          {/* <img src={Logo1} alt="logo1" className="absolute w-1/2 sm:w-auto mb-[75vh]"/> */}
          {/* <img src={logo2} alt="logo2" className="mt-[0vh] mb-[0vh] lg:h-[40vh] sm:h-[25vh] w-auto"/> */}
        </div>
        {/* <h2 className='mt-5 text-3xl sm:mt-10 sm:text-4xl'>TRAIN WITH STELIOS</h2> */}
        {/* <p className="mt-2 text-xl sm:mt-5 sm:text-2xl">PERSONAL TRAINING</p> */}
        <div className="relative scale-150 mt-[60vh] sm:mt-[60vh]">
          <CustomButton text="Book Consultation" onClick={() => contactRef.current.scrollIntoView({ behavior: 'smooth' })} />
        </div>
      </div>
      </div>
    );
  });
  export default HeroSection;
  
