import React, { forwardRef } from 'react';
import img from '../assets/images/footerImg.jpg';
import Tittle from './Tittle';
import SectionDivider from './SectionDivisor';

const About = forwardRef(({ id }, ref) => {
    return (
        <div id={id} ref={ref} >
            <SectionDivider text={"ABOUT"}/>
            <div className="flex pl-0 lg:px-20 sm:px-20 min-h-[80vh] flex-col w-screen lg:flex-row items-center justify-center overflow-clip">
                <div className="w-screen lg:w-1/2 p-4">
                    <p className="font-century-gothic leading-[2rem] lg:leading-[3.5rem] text-[18px] md:text-[20px] lg:text-[22px] sm:text-justify md:text-justify lg:text-justify text-black"> 
                        I'm your dedicated Fitness Personal Trainer, and I'm here to 
                        guide you every step of the way towards achieving your 
                        fitness goals. Whether you're a seasoned athlete looking to 
                        push your limits or a complete beginner taking your first 
                        steps towards a healthier lifestyle, I'm committed to 
                        helping you unlock your full potential.
                    </p>
                    <p className="font-century-gothic leading-[2rem] lg:leading-[3.5rem] text-[18px] md:text-[20px] lg:text-[22px] sm:text-justify md:text-justify lg:text-justify mt-4 text-black"> 
                        With a passion for fitness that goes beyond mere workouts, 
                        I believe in a holistic approach to health. It's not just about 
                        lifting weights or running on a treadmill, it's about crafting 
                        a sustainable, balanced, and fulfilling lifestyle.
                    </p>
                </div>
                <div className="w-full flex h-auto items-center justify-center object-center align-middle lg:w-1/2 p-4">
                    <div className='flex items-center justify-center object-center align-middle w-full lg:w-1/2 overflow-clip'>
                        <img src={img} alt="img" className="w-full"/>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default About;
