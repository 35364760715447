import React from 'react';

const FullStar = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#000000">
    <path d="M12 2l2.4 7.2h7.6l-6 4.8 2.4 7.2L12 16.4l-6 4.8 2.4-7.2-6-4.8h7.6L12 2z"/>
  </svg>
);

const EmptyStar = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#000000" strokeWidth="2">
    <path d="M12 2l2.4 7.2h7.6l-6 4.8 2.4 7.2L12 16.4l-6 4.8 2.4-7.2-6-4.8h7.6L12 2z"/>
  </svg>
);

const StarRating = ({ rating }) => {
  const totalStars = 5;
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {[...Array(totalStars)].map((_, i) => (
        i < rating ? <FullStar key={i} /> : <EmptyStar key={i} />
      ))}
    </div>
  );
};

export default StarRating;
