import React, { useState } from 'react';
import CustomButton from './CustomButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ContactForm() {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [formData, setFormData] = useState({
      fullName: '',
      email: '',
      phone: '',
      message: ''
    });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Hello")
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbzgx_4szCfbMP3Dkahkq4hOBZ_dMr95bhcdB_N-qnVvvwgtQjCyMfgmWw_MSoyUiNuBfA/exec', {
        method: 'POST',
        mode: 'no-cors',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      if (data.result === 'success') {
        setIsSubmitted(true);
        // Optionally, reset form data after successful submission
        setFormData({
            fullName: '',
            email: '',
            phone: '',
            message: ''
        });
    } else {
        setIsSubmitted(true);
        setFormData({
            fullName: '',
            email: '',
            phone: '',
            message: ''
        });
        console.log("Fail "+ data.message);
    }
} catch (error) {
        setIsSubmitted(true);
        setFormData({
            fullName: '',
            email: '',
            phone: '',
            message: ''
        });
        console.log("Error "+ error);
    }
  }
  

  return (
    <form onSubmit={handleSubmit} className="w-[100vw] sm:w-[100vw] lg:w-[40vw] p-4">

      <div className="flex items-start mb-4">
              <label className="w-24 text-[16px] text-gray-700 text-sm font-bold mr-4" htmlFor="fullName">
                Full Name
              </label>
              <input 
                className="shadow appearance-none border w-full border-black h-10 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                type="text" 
                name="fullName" 
                id="fullName"
                value={formData.fullName} 
                onChange={handleChange}
                required
              />
            </div>

      <div className="flex items-start mt-10 mb-4">
        <label className="w-24 text-[16px] text-gray-700 text-sm font-bold mr-4" htmlFor="email">
          Email
        </label>
        <input 
          className="shadow appearance-none border w-full border-black h-10 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="email" 
          name="email" 
          id="email"
          value={formData.email} 
          onChange={handleChange}
          required
        />
      </div>

      <div className="flex items-start mt-10 mb-4">
        <label className="w-24 text-[16px] text-gray-700 text-sm font-bold mr-4" htmlFor="phone">
          Phone
        </label>
        <input 
          className="shadow appearance-none border w-full border-black h-10 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
          type="tel" 
          name="phone" 
          id="phone"
          value={formData.phone} 
          onChange={handleChange}
        />
      </div>

      <div className="flex items-start mt-10 mb-4">
        <label className="w-24 text-[16px] text-gray-700 text-sm font-bold mr-4" htmlFor="message">
          Message
        </label>
        <textarea
          className="shadow appearance-none border border-black w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          name="message" 
          id="message"
          rows="4"
          value={formData.message} 
          onChange={handleChange}
          required
        ></textarea>
      </div>


      <div className="relative float-right">
        <CustomButton color="#fcfc04" text="SUBMIT"/>
      </div>
        {
            isSubmitted && <p className="text-green-500">Form submitted successfully!</p>
        }

    </form>
  );
}
