import React from 'react';
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import ThunderImage from '../assets/images/Thunder.png';  // Replace with your image path
import ThunderLine from '../assets/images/ThunderLine.png';  // Replace with your image path
import Logo1 from '../assets/images/TWS2.png';  // Replace with your image path
import HamburgerMenu from './HamburgerMenu';

export default function SectionDivider({text}) {
    return (
        <div className='w-screen'>
            <div className="bg-black h-24 w-full align-center items-center justify-center flex">
                <div className='flex flex-row'>
                    <div className=' bg-[#fcfc04] h-[0.2rem] w-[0.5rem]'></div>
                </div>
                <div className='flex flex-row'>
                    <div className=' bg-[#fcfc04] h-[0.525rem] w-[.7rem]'></div>
                </div>
                <div className='flex flex-row'>
                    <div className='bg-[#fcfc04] h-[0.2rem] w-[0.3rem]'></div>
                </div>
                <div className='justify-center items-center flex flex-row'>
                    <div className=' bg-[#fcfc04] h-8 w-[0.35rem]'></div>
                    <div className=' bg-[#fcfc04] h-[0.15rem] w-[0.12rem]'></div>
                    <div className=' bg-[#fcfc04] h-8 w-[0.35rem]'></div>
                    <div className=' bg-[#fcfc04] h-[0.15rem] w-[0.12rem]'></div>
                    <div className=' bg-[#fcfc04] h-8 w-[0.35rem]'></div>
                </div>
                <div className='flex flex-row'>
                    <div className=' bg-[#fcfc04] h-[0.15rem] w-[1rem]'></div>
                </div>
                <div className="font-semibold text-lg mx-2">
                    <p>{text}</p>
                </div>

                <div className='flex flex-row'>
                    <div className=' bg-[#fcfc04] h-[0.15rem] w-[1rem]'></div>
                </div>
                <div className='justify-center items-center flex flex-row'>
                    <div className=' bg-[#fcfc04] h-8 w-[0.35rem]'></div>
                    <div className=' bg-[#fcfc04] h-[0.15rem] w-[0.12rem]'></div>
                    <div className=' bg-[#fcfc04] h-8 w-[0.35rem]'></div>
                    <div className=' bg-[#fcfc04] h-[0.15rem] w-[0.12rem]'></div>
                    <div className=' bg-[#fcfc04] h-8 w-[0.35rem]'></div>
                </div>
                <div className='flex flex-row'>
                    <div className=' bg-[#fcfc04] h-[0.15rem] w-[0.3rem]'></div>
                </div>
                <div className='flex flex-row'>
                    <div className=' bg-[#fcfc04] h-[0.525rem] w-[.7rem]'></div>
                </div>
                <div className='flex flex-row'>
                    <div className=' bg-[#fcfc04] h-[0.15rem] w-[0.5rem]'></div>
                </div>
            </div>
        </div>
    );
}
