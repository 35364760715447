import React from 'react';

export default function CustomButton({ text, color = 'black',onClick }) {
    return (
        <div className="inline-block bg-black rounded-full shadow-md hover:shadow-lg transition-all duration-300">
            <button className={`text-[#fcfc04] px-6 py-2 rounded-full font-semibold hover:bg-gray-800 transition-all duration-150`} onClick={onClick}>
                {text}
            </button>
        </div>
    );
}
